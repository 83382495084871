<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="6" class="d-flex flex-column">
                <h5>Lead Time</h5>
                <validation-provider
                  #default="{ errors }"
                  class="d-flex"
                  name="lead-time"
                  rules="requeridoE"
                >
                  <b-form-group class="w-100" label="Lead Time (Dias)" label-for="lead-time">
                    <b-form-input
                      v-model="items.leadtime"
                      id="lead-time"
                      :readonly="!items.leadtime_modified"
                      placeholder="Lead Time"
                      :disabled="view ? true : false"
                      @input="setLead(items.leadtime)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  <span class="w-50 d-flex justify-content-start px-2 align-items-center">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="items.leadtime_modified"
                      name="checkbox-1"
                      :disabled="view ? true : false"
                    >
                      Modificar
                    </b-form-checkbox>
                  </span>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="justify"
                  :rules="{
                    requeridoE: items.leadtime_modified ? true : false
                  }"
                >
                  <b-form-group label="Justificación" label-for="justify" class="mb-0">
                    <b-form-textarea
                      id="justify"
                      placeholder="Justificación"
                      rows="10"
                      :disabled="!items.leadtime_modified"
                      no-resize
                      v-model="items.leadtime_comment"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <h5>Localización</h5>
                <!-- <validation-provider #default="{ errors }" name="especialidad" rules="requeridoE">
                  <b-form-group label="Especialidad" label-for="especialidad">
                    <v-select
                      v-model="items.speciality_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="especialidades"
                      label="code"
                      input-id="especialidad"
                      :reduce="(especialidad) => especialidad.id"
                      placeholder="Especialidad"
                      @input="changeEspe(items.speciality_id)"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider> -->
                <validation-provider #default="{ errors }" name="location1" rules="">
                  <b-form-group label="Localización 1" label-for="location1">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion1"
                      label="code"
                      input-id="location1"
                      :reduce="(locacion1) => locacion1.id"
                      v-model="items.location_1_id"
                      placeholder="Localización 1"
                      @input="changeLocal(items.location_1_id, 1)"
                      :disabled="view ? true : false"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="location2" rules="">
                  <b-form-group label="Localización 2" label-for="location2">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion2"
                      label="code"
                      input-id="location2"
                      :reduce="(locacion2) => locacion2.id"
                      v-model="items.location_2_id"
                      placeholder="Localización 2"
                      @input="changeLocal(items.location_2_id, 2)"
                      :disabled="view ? true : false"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="location3" rules="">
                  <b-form-group label="Localización 3" label-for="location3">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion3"
                      label="code"
                      input-id="location3"
                      :reduce="(locacion3) => locacion3.id"
                      v-model="items.location_3_id"
                      placeholder="Localización 3"
                      @input="changeLocal(items.location_3_id, 3)"
                      :disabled="view ? true : false"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="location4" rules="">
                  <b-form-group label="Localización 4" label-for="location4">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion4"
                      label="code"
                      input-id="location4"
                      :reduce="(locacion4) => locacion4.id"
                      v-model="items.location_4_id"
                      placeholder="Localización 4"
                      :disabled="view ? true : false"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12">
                <h5>Actividad</h5>
              </b-col>
            </b-row>
            <b-row class="">
              <b-col md="6" class="">
                <validation-provider #default="{ errors }" name="id-activity" rules="">
                  <b-form-group label="ID Actividad" label-for="id-activity">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="actividades"
                      v-model="items.activity_id"
                      label="code"
                      input-id="id-activity"
                      :reduce="(actividades) => actividades.id"
                      placeholder="Actividad"
                      @input="selectActivity(items.activity_id)"
                      :disabled="view ? true : false"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="activity" rules="">
                  <b-form-group label="Actividad" label-for="activity">
                    <b-form-input
                      readonly
                      v-model="activity_code"
                      id="activity"
                      placeholder="Actividad"
                      :disabled="view ? true : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LocalizationService from '@/services/LocalizationService'
import SpecialityService from '@/services/SpecialityService'
import vSelect from 'vue-select'
import { create } from 'echarts/lib/coord/cartesian/Grid'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  props: ['edit', 'spe_id', 'leadTime', 'project_id', 'view'],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      showLoading: false,
      tabIndex: 0,
      project: null,
      especialidades: [],
      locacion1: [],
      locacion2: [],
      locacion3: [],
      locacion4: [],
      actividades: [],
      activity_code: '',
      items: {
        leadtime: '',
        leadtime_modified: false,
        leadtime_comment: '',
        activity_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null
      }
    }
  },
  async mounted() {
    if (!this.edit) {
      this.getData()
    }
    
  },
  // created() {
  //   this.getData()
  // },
  methods: {
    setDat(id) {
      this.items.speciality_id = id
      this.changeEspe(id)
    },
    setLead(nuevoValor) {
      // Este método manejará la modificación del valor de leadtime en el componente datos
      // Emite un evento para notificar al componente padre sobre la modificación
      this.items.leadtime = nuevoValor

      this.$emit('leadtimeModificado', nuevoValor);
    },
    
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.items.location_1_id = null
      this.items.location_2_id = null
      this.items.location_3_id = null
      this.items.location_4_id = null
    },
    async getData() { 
      const idRoute = this.edit ? Number(this.$parent.$parent.$parent.$parent.dataItems.project_id) : Number(this.$route.params.id)
      let url2 =
        `?filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: idRoute }])
      const resp = await LocalizationService.getActivitys(url2, this.$store)
   
      if (resp.status) {
        this.actividades = resp.data.rows
        if (this.edit) {
          if (this.$parent.$parent.$parent.$parent.dataItems.activity_id != null) {
            this.selectActivity(this.$parent.$parent.$parent.$parent.dataItems.activity_id)
          }
          
        }
      }
      let url1 =
        `?filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: idRoute }])
      const resSpec = await SpecialityService.getSpecialityById(url1, this.$store)
 
      if (resSpec.status) {
        this.especialidades = resSpec.data.rows
      }

      let url =
        `?filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: idRoute }])
      const respLocal1 = await LocalizationService.getLocations1(url, this.$store)
      if (respLocal1.status) {      
        this.locacion1 = respLocal1.data.rows
        if (!this.edit) {
          this.resetForm()
        }
      }

      //EDITAR
      if (this.edit) {
        if (this.$parent.$parent.$parent.$parent.dataItems.location_1_id != null) {
          this.changeLocal(this.$parent.$parent.$parent.$parent.dataItems.location_1_id,1)
        }
        if (this.$parent.$parent.$parent.$parent.dataItems.location_2_id != null) {
          this.changeLocal(this.$parent.$parent.$parent.$parent.dataItems.location_2_id,2)
        }
        if (this.$parent.$parent.$parent.$parent.dataItems.location_3_id != null) {
          this.changeLocal(this.$parent.$parent.$parent.$parent.dataItems.location_3_id,3)
        }
      }
    },
    selectActivity(id) {
      console.log('id actividad',id)
      console.log('this.actividades',this.actividades)
      if (this.actividades.length > 0) {
        const act = this.actividades.filter((e) => e.id == id)
        
        this.activity_code = act[0].description
      }
    },
    async changeLocal(id, level) {
      var resp = null
      let url =
        `?filter=` +
        JSON.stringify([{ keyContains: 'location_' + level + '_id', key: 'equals', value: id }])
      if (level == 1) {
        resp = await LocalizationService.getLocations2(url, this.$store)
        if (resp.status) {
          this.locacion2 = resp.data.rows
          this.$refs.refFormObserver.reset()
        }
      } else if (level == 2) {
        resp = await LocalizationService.getLocations3(url, this.$store)
        if (resp.status) {
          this.locacion3 = resp.data.rows
          this.$refs.refFormObserver.reset()
        }
      } else if (level == 3) {
        resp = await LocalizationService.getLocations4(url, this.$store)
        if (resp.status) {
          this.locacion4 = resp.data.rows
          this.$refs.refFormObserver.reset()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
