<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <b-card no-body>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="6">
                <h5>Asignar Dueño</h5>
                <div class="d-flex w-100 flex-md-row flex-column">
                  <validation-provider class="w-100 mr-2" #default="{ errors }" name="dueño" rules="requeridoE">
                    <b-form-group label="Dueño" label-for="dueño">
                      <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="duenos" label="name"
                        input-id="dueño" :reduce="(duenos) => duenos.id" placeholder="Dueño" v-model="items.owner_id"
                        @input="changeDueno(items.owner_id)" :disabled="view ? true : false">
                        <template v-slot:selected-option="option">
                          {{ isFirstLoad ? formatFirstLoad(option) : formatDefault(option) }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.name }} - {{ option.area ? option.area.code : '' }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger" v-if="labelUserChanged">El usuario {{firstOwnerName}} ha sido eliminado/migrado</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider class="w-100" #default="{ errors }" name="area" rules="">
                    <!-- <b-form-group label="Area" label-for="area">
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="areas"
                        label="name"
                        input-id="area"
                        :reduce="(areas) => areas.id"
                        placeholder="Area"
                        v-model="items.area_id"
                        @input="getOwners(items.area_id)"
                      >
                        <template v-slot:selected-option="option">
                          {{ option.code }} - {{ option.description }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.code }} - {{ option.description }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group> -->
                    <b-form-group label="Area" label-for="area">
                      <b-form-input readonly v-model="owner_area" id="area" placeholder="Area"
                        :disabled="view ? true : false" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                </div>
              </b-col>
              <b-col md="6">
                <h5>Dependencia</h5>
                <validation-provider #default="{ errors }"
                  class="d-flex h-100 mt-2 justify-content-lg-start justify-content-center" name="dependency"
                  rules="requeridoE">        
                  <b-form-group label-for="dependency" class="h-100">
                    <div class="d-flex w-50 align-items-center mt-1 ml-1">
                      <span class="mr-2">Interna</span>
                      <b-form-checkbox v-model="items.dependency" name="dependency" switch :disabled="view ? true : false"
                        @change.capture="changeDependecy(items.dependency)">
                      </b-form-checkbox>
                      <span class="ml-2">Externa</span>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                </validation-provider>
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2">
              <b-col md="12">
                
              </b-col>
            </b-row> -->
            <b-row v-if="items.dependency != false" class="mb-1">
              <h5>Seguimiento a</h5>
            </b-row>
            <b-row v-if="items.dependency != false" class="mb-1">
              <b-col md="6">
                <validation-provider #default="{ errors }" class="" name="seguimiento" :rules="{
                  requeridoE: items.dependency != false ? true : false
                }">
                  <b-form-radio v-model="items.follow_up_type" name="seguimiento" value="Cliente/Supervisión" class="mb-2"
                    @change="handleRadioFollow()">
                    Cliente/Supervisión
                  </b-form-radio>
                  <b-form-radio v-model="items.follow_up_type" name="seguimiento" value="Tercero" class="mb-2"
                    @change="handleRadioFollow()">
                    Tercero (SC / Proveedores)
                  </b-form-radio>
                  <b-form-radio v-model="items.follow_up_type" name="seguimiento" value="Oficina Principal" class="mb-2"
                    @change="handleRadioFollow()">
                    Oficina Principal (Areas Funcionales)
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-lg-row flex-column">
                <validation-provider class="w-100 mr-2" #default="{ errors }" name="codueño" :rules="{
                  requeridoE: items.dependency != false ? true : false
                }">
                  <b-form-group label="Co-Dueño" label-for="codueño">
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="coduenos" label="name"
                      input-id="codueño" :reduce="(coduenos) => coduenos.id" placeholder="Co-Dueño"
                      v-model="items.co_owner_id" @input="changeCoDueno(items.co_owner_id)">
                      <template v-slot:selected-option="option">
                        {{ option.name }} - {{ option.area ? option.area.code : '' }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.name }} - {{ option.area ? option.area.code : '' }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider class="w-100" #default="{ errors }" name="co_owner_area" rules="">
                  <b-form-group label="Area" label-for="co_owner_area">
                    <b-form-input readonly v-model="co_owner_area" id="co_owner_area" placeholder="Area" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import OwnerService from '@/services/OwnerService'
import CoOwnerService from '@/services/CoOwnerService'
import AreasService from '@/services/AreasService'
import RestrictionsService from '@/services/RestrictionsService'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  props: ['edit', 'view'],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  computed: {
    labelUserChanged() {
      return (this.items.owner_id == this.firstOwnerId && this.userGone)
    }
  },
  data() {
    return {
      ownerExist: false,
      showLoading: false,
      tabIndex: 0,
      duenos: [],
      coduenos: [],
      areas: [],
      co_owner_area: '',
      owner_area: '',
      userGone: false,
      firstAreaCode: null,
      firstOwnerId: null,
      firstOwnerName: '',
      isFirstLoad: true,
      items: {
        owner_id: null,
        area_id: null,
        co_owner_id: null,
        dependency: false,
        follow_up_type: '',
      },
      filterCoOwner: []
    }
  },
  mounted() {
    this.getData()
    this.handleRadioFollow();
  },
  
  methods: {
    formatFirstLoad(option) {
      // Lógica para el formato especial de la primera carga
      // Puedes cambiar esto según tus necesidades
      console.log("cargado primera vez")
      return `${this.firstOwnerName} - ${this.firstAreaCode ? this.firstAreaCode : ''}`;
    },
    formatDefault(option) {
      // Lógica para el formato predeterminado
      return `${option.name} - ${option.area ? option.area.code : ''}`;
    },
    async handleRadioFollow() {
      this.filterCoOwner = [];
      const idRoute = this.edit
        ? Number(localStorage.getItem("project_id"))
        : Number(this.$route.params.id);
        this.co_owner_area = ""
        this.items.co_owner_id = null
      if (this.items.follow_up_type == 'Oficina Principal') {
        this.filterCoOwner.push({
          keyContains: "is_show",
          key: "equals",
          value:true,
        });    
     
        this.filterCoOwner.push({
          keyContains: "area.type",
          key: "equals",
          value: '004',
        });
      }
      if (this.items.follow_up_type == 'Tercero') {
        this.filterCoOwner.push({
          keyContains: "is_show",
          key: "equals",
          value:true,
        });
        this.filterCoOwner.push({
          keyContains: "area.project_id",
          key: "equals",
          value: idRoute,
        });
        this.filterCoOwner.push({
          keyContains: "area.type",
          key: "equals",
          value: '002',
        });
      }
      if (this.items.follow_up_type == 'Cliente/Supervisión') {
        this.filterCoOwner.push({
          keyContains: "is_show",
          key: "equals",
          value:true,
        });
        this.filterCoOwner.push({
          keyContains: "area.project_id",
          key: "equals",
          value: idRoute,
        });
        this.filterCoOwner.push({
          keyContains: "area.type",
          key: "equals",
          value: '003',
        });
      }

      const url =
        `?limit=100&filter=` +
        JSON.stringify(this.filterCoOwner);
      const resCoOwner = await CoOwnerService.getCoOwners(url, this.$store);
      console.log("resCoOwner", resCoOwner);
      if (resCoOwner.status) {
        this.coduenos = resCoOwner.data.rows;
      }
    },
    async getData() {
      this.showLoading = true
      // const idRoute = this.edit ? Number(this.$parent.$parent.$parent.$parent.dataItems.project_id) : Number(this.$route.params.id)
      const idRoute = this.edit ? Number(localStorage.getItem('project_id')) : Number(this.$route.params.id)
      const url = `?limit=100&filter=` + JSON.stringify([{ keyContains: 'area.project_id', key: 'equals', value: idRoute }])
      const resOwner = await OwnerService.getOwners(url, this.$store)
      console.log('resOwner', resOwner)
      console.log("ITEMS", this.items)
      if (resOwner.status) {
        console.log('owner');
        this.duenos = resOwner.data.rows
      }

        
      //editar
      if (this.edit) {
        const getRestri = await RestrictionsService.getRestriccionById(this.$route.params.id,this.$store)
        console.log('getRestriccion edit', getRestri)
        if(getRestri.data.owner){
          this.ownerExist = true
          this.items.owner_id = getRestri.data.owner.id
          console.log("ENTRO AL IFFFF")
        }
        console.log("ES EDITADO MUESTRAME EL OWNER ID DE ITEMS", this.items.owner_id)
        if (this.items.owner_id != null) {
          console.log("HAY PPRIMERO DUENO",this.$parent.$parent.$parent.$parent.dataItems.owner_id)
          console.log(this.$parent.$parent.$parent.$parent.dataItems)
          // this.getOwners(this.$parent.$parent.$parent.$parent.dataItems.area_id)
          this.changeDueno(this.$parent.$parent.$parent.$parent.dataItems.owner_id)
        }
        if (this.$parent.$parent.$parent.$parent.dataItems.co_owner_id != null) {
          console.log("HAY PPRIMERO CODUENO",this.$parent.$parent.$parent.$parent.dataItems.co_owner_id)
          this.changeCoDueno(this.$parent.$parent.$parent.$parent.dataItems.co_owner_id)
        }
        console.log(" NO ENTRO ITEMS", this.items)
      }
      this.showLoading = false
    },
    async getOwners(id) {
      const url = `?limit=100&filter=` + JSON.stringify([{ keyContains: 'area_id', key: 'equals', value: id }])
      const resOwner = await OwnerService.getOwners(url, this.$store)
      console.log('resOwner', resOwner)
      if (resOwner.status) {
        console.log('owner');
        this.duenos = resOwner.data.rows
      }
    },
    changeDueno(id) {
      const fil = this.duenos.filter((e) => e.id == id)
      console.log('fil changedueño', fil)
      console.log("primera vez changedueño")
      /* if(id == this.firstOwnerId && this.userGone){
        //dar estilo al texto del input de Dueno
        this.labelUserChanged = true

      }else{
        this.labelUserChanged = false
      } */
      if (fil.length > 0) {
        this.owner_area = fil[0].area.code + ' - ' + fil[0].area.description
      }
      this.isFirstLoad = false;
    },
    changeCoDueno(id) {
      const fil = this.coduenos.filter((e) => e.id == id)
      if (fil.length > 0) {
        this.co_owner_area = fil[0].area.code + ' - ' + fil[0].area.description
      }
    },
    changeDependecy(val) {
      console.log('val', val)
      if (val) {
        val = 'Externa'
        this.items.follow_up_type="Cliente/Supervisión"
        this.co_owner_area = ""
        this.handleRadioFollow()
      }
      else {
        val = 'Interna'
      }
      if (val == 'Interna') {      
        this.items.co_owner_id = null
        this.items.follow_up_type = ''
      }
    
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
