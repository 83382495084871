var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('h5',[_vm._v("Asignar Dueño")]),_c('div',{staticClass:"d-flex w-100 flex-md-row flex-column"},[_c('validation-provider',{staticClass:"w-100 mr-2",attrs:{"name":"dueño","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dueño","label-for":"dueño"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.duenos,"label":"name","input-id":"dueño","reduce":function (duenos) { return duenos.id; },"placeholder":"Dueño","disabled":_vm.view ? true : false},on:{"input":function($event){return _vm.changeDueno(_vm.items.owner_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.isFirstLoad ? _vm.formatFirstLoad(option) : _vm.formatDefault(option))+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" - "+_vm._s(option.area ? option.area.code : '')+" ")]}}],null,true),model:{value:(_vm.items.owner_id),callback:function ($$v) {_vm.$set(_vm.items, "owner_id", $$v)},expression:"items.owner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.labelUserChanged)?_c('small',{staticClass:"text-danger"},[_vm._v("El usuario "+_vm._s(_vm.firstOwnerName)+" ha sido eliminado/migrado")]):_vm._e()],1)]}}],null,true)}),_c('validation-provider',{staticClass:"w-100",attrs:{"name":"area","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Area","label-for":"area"}},[_c('b-form-input',{attrs:{"readonly":"","id":"area","placeholder":"Area","disabled":_vm.view ? true : false},model:{value:(_vm.owner_area),callback:function ($$v) {_vm.owner_area=$$v},expression:"owner_area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('h5',[_vm._v("Dependencia")]),_c('validation-provider',{staticClass:"d-flex h-100 mt-2 justify-content-lg-start justify-content-center",attrs:{"name":"dependency","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"h-100",attrs:{"label-for":"dependency"}},[_c('div',{staticClass:"d-flex w-50 align-items-center mt-1 ml-1"},[_c('span',{staticClass:"mr-2"},[_vm._v("Interna")]),_c('b-form-checkbox',{attrs:{"name":"dependency","switch":"","disabled":_vm.view ? true : false},on:{"!change":function($event){return _vm.changeDependecy(_vm.items.dependency)}},model:{value:(_vm.items.dependency),callback:function ($$v) {_vm.$set(_vm.items, "dependency", $$v)},expression:"items.dependency"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Externa")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),(_vm.items.dependency != false)?_c('b-row',{staticClass:"mb-1"},[_c('h5',[_vm._v("Seguimiento a")])]):_vm._e(),(_vm.items.dependency != false)?_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"seguimiento","rules":{
                requeridoE: _vm.items.dependency != false ? true : false
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-radio',{staticClass:"mb-2",attrs:{"name":"seguimiento","value":"Cliente/Supervisión"},on:{"change":function($event){return _vm.handleRadioFollow()}},model:{value:(_vm.items.follow_up_type),callback:function ($$v) {_vm.$set(_vm.items, "follow_up_type", $$v)},expression:"items.follow_up_type"}},[_vm._v(" Cliente/Supervisión ")]),_c('b-form-radio',{staticClass:"mb-2",attrs:{"name":"seguimiento","value":"Tercero"},on:{"change":function($event){return _vm.handleRadioFollow()}},model:{value:(_vm.items.follow_up_type),callback:function ($$v) {_vm.$set(_vm.items, "follow_up_type", $$v)},expression:"items.follow_up_type"}},[_vm._v(" Tercero (SC / Proveedores) ")]),_c('b-form-radio',{staticClass:"mb-2",attrs:{"name":"seguimiento","value":"Oficina Principal"},on:{"change":function($event){return _vm.handleRadioFollow()}},model:{value:(_vm.items.follow_up_type),callback:function ($$v) {_vm.$set(_vm.items, "follow_up_type", $$v)},expression:"items.follow_up_type"}},[_vm._v(" Oficina Principal (Areas Funcionales) ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-lg-row flex-column",attrs:{"md":"6"}},[_c('validation-provider',{staticClass:"w-100 mr-2",attrs:{"name":"codueño","rules":{
                requeridoE: _vm.items.dependency != false ? true : false
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Co-Dueño","label-for":"codueño"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.coduenos,"label":"name","input-id":"codueño","reduce":function (coduenos) { return coduenos.id; },"placeholder":"Co-Dueño"},on:{"input":function($event){return _vm.changeCoDueno(_vm.items.co_owner_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" - "+_vm._s(option.area ? option.area.code : '')+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" - "+_vm._s(option.area ? option.area.code : '')+" ")]}}],null,true),model:{value:(_vm.items.co_owner_id),callback:function ($$v) {_vm.$set(_vm.items, "co_owner_id", $$v)},expression:"items.co_owner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"w-100",attrs:{"name":"co_owner_area","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Area","label-for":"co_owner_area"}},[_c('b-form-input',{attrs:{"readonly":"","id":"co_owner_area","placeholder":"Area"},model:{value:(_vm.co_owner_area),callback:function ($$v) {_vm.co_owner_area=$$v},expression:"co_owner_area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }