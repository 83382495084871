<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          next-button-text="Siguiente"
          back-button-text="Atrás"
          finish-button-text="Finalizar"
          class="steps-transparent mb-3"
          @on-complete="saveData"
        >
          <!-- account detail tab -->
          <tab-content
            title="Establecer Rest."
            icon="feather icon-folder"
            :before-change="validEstablecer"
          >
            <establecer
              @lead="lead"
              :edit="false"
              ref="establecer"
            />
          </tab-content>

          <!-- personal details -->
          <tab-content
            title="Datos Rest."
            icon="feather icon-clipboard"
            :before-change="validDatos"
          >
            <datos
              :leadTime="leadTime"
              :spe_id="id_espe"
              :edit="false"
              @leadtimeModificado="handleLeadtimeModified"
              ref="datos"
            />
          </tab-content>

          <!-- address -->
          <tab-content
            title="Dueño Rest."
            icon="feather icon-user"
            :before-change="validDueno"
          >
            <dueno
              :edit="false"
              ref="dueno"
            />
          </tab-content>

          <!-- social link -->
          <tab-content
            title="Definición Fechas"
            icon="feather icon-calendar"
            :before-change="validDefinicion"
          >
            <definicion
              :edit="false"
              ref="definicion"
            />
          </tab-content>

          <tab-content
            title="Archivos"
            icon="feather icon-upload"
          >
            <archivos
              :edit="false"
              ref="archivos"
            />
          </tab-content>
        </form-wizard>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import FilesService from '@/services/FilesService'
import RestrictionsService from '@/services/RestrictionsService'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Vue from 'vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import archivos from './steps/archivos.vue'
import datos from './steps/datos.vue'
import definicion from './steps/definicion.vue'
import dueno from './steps/dueno.vue'
import establecer from './steps/establecer.vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      id_espe: null,
      leadTime: '',
      showLoading: false,
      dataItems: {
        code: '',
        description: '',
        detail: '',
        dependency: '',
        leadtime: '',
        leadtime_modified: '',
        leadtime_comment: '',
        priority: '',
        follow_up_type: '',
        status: '',
        type: '',
        critic_route: false,
        id_p6: null,
        date_identified: null,
        date_required: null,
        date_commited: null,
        date_released: null,
        time_anticipation: null,
        project_id: null,
        speciality_id: null,
        activity_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null,
        category_id: null,
        sub_category_id: null,
        owner_id: null,
        co_owner_id: null,
        user_id: null,
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  components: {
    establecer,
    datos,
    archivos,
    definicion,
    dueno,
    FormWizard,
    TabContent,
    vSelect,
  },
  mounted() {
    this.dataItems.project_id = parseInt(this.$route.params.id)
    this.dataItems.user_id = this.userData.id
  },
  methods: {
    handleLeadtimeModified(nuevoValor) {
      // Este método manejará el evento cuando el valor de leadtime se modifique en el componente datos
      // Actualiza el valor de leadtime en el componente definicion
      this.$refs.definicion.items.leadtime = nuevoValor
      console.log('Leadtime actualizado en Definicion:', nuevoValor)
    },
    formSubmitted() {
      console.log('aqui?')
    },
    cancel() {
      this.$router.push('/restricciones')
    },
    selectSpe(id) {
      this.$refs.datos.setDat(id)
      this.$refs.datos.resetForm()
    },
    lead(led) {
      this.$refs.datos.setLead(led)
    },
    validEstablecer() {
      return new Promise((resolve, reject) => {
        // var validate = null
        this.$refs.establecer.$refs.refFormObserver.validate().then(success => {
          if (success) {
            this.dataItems.code = this.$refs.establecer.items.code
            this.dataItems.description = this.$refs.establecer.items.description
            this.dataItems.detail = this.$refs.establecer.items.detail
            this.dataItems.speciality_id = this.$refs.establecer.items.speciality_id
            this.dataItems.priority = this.$refs.establecer.items.priority
            this.dataItems.category_id = this.$refs.establecer.items.category_id
            this.dataItems.sub_category_id = this.$refs.establecer.items.sub_category_id
            if(this.$refs.establecer.items.critic_route){
              this.dataItems.critic_route = 'true'
            }else{
              this.dataItems.critic_route = 'false'
            }
            if(this.dataItems.critic_route == 'true'){
              this.dataItems.id_p6 = this.$refs.establecer.items.id_p6
            }else{
              this.dataItems.id_p6 = null
            }
            if (this.$refs.establecer.items.type) {
              this.dataItems.type = 'critica'
            } else {
              this.dataItems.type = 'campo'
            }
            /* this.dataItems.type = this.$refs.establecer.items.type */
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDatos() {
      return new Promise((resolve, reject) => {
        this.$refs.datos.$refs.refFormObserver.validate().then(success => {
          if (success) {
            this.dataItems.leadtime = String(this.$refs.datos.items.leadtime)
            this.dataItems.leadtime_modified = this.$refs.datos.items.leadtime_modified
            this.dataItems.leadtime_comment = this.$refs.datos.items.leadtime_comment
            this.dataItems.activity_id = this.$refs.datos.items.activity_id
            // this.dataItems.speciality_id = this.$refs.datos.items.speciality_id
            this.dataItems.location_1_id = this.$refs.datos.items.location_1_id
            this.dataItems.location_2_id = this.$refs.datos.items.location_2_id
            this.dataItems.location_3_id = this.$refs.datos.items.location_3_id
            this.dataItems.location_4_id = this.$refs.datos.items.location_4_id
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDueno() {
      return new Promise((resolve, reject) => {
        this.$refs.dueno.$refs.refFormObserver.validate().then(success => {
          if (success) {
            this.dataItems.owner_id = this.$refs.dueno.items.owner_id
            this.dataItems.co_owner_id = this.$refs.dueno.items.co_owner_id
            this.dataItems.dependency = this.$refs.dueno.items.dependency
            if (this.$refs.dueno.items.dependency) {
              this.dataItems.dependency = 'Externa'
            } else {
              this.dataItems.dependency = 'Interna'
            }
            this.dataItems.follow_up_type = this.$refs.dueno.items.follow_up_type
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDefinicion() {
      console.log('assad')
      console.log('this.$refs.definicion.items BEFORE', this.$refs.definicion.items)
      return new Promise((resolve, reject) => {
        this.$refs.definicion.$refs.refFormObserver.validate().then(success => {
          if (success) {
            this.dataItems.date_identified = new Date(this.$refs.definicion.items.date_identified)
            this.dataItems.date_required = new Date(this.$refs.definicion.items.date_required)
            this.dataItems.date_commited = new Date(this.$refs.definicion.items.date_commited)
            this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
            this.dataItems.date_released = this.dataItems.date_released ? new Date(this.$refs.definicion.items.date_released) : null
            this.dataItems.status = this.$refs.definicion.items.status
            console.log('this.$refs.definicion.items AFTER', this.$refs.definicion.items)

            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async saveData() {
      this.showLoading = true
      let archivos = this.$refs.archivos.records
      let datos = new FormData()
      const save = await RestrictionsService.saveRestricciones(this.dataItems, this.$store)
      console.log('DATA DE SAVE', save)
      if (save.status) {
        if (archivos.length > 0) {
          let resp = null
          for (let index = 0; index < archivos.length; index++) {
            const element = archivos[index]
            console.log('IMAGEN A GUATDAR', element)
            datos.append('description', element.description)
            datos.append('file', element.file)
            datos.append('restriction_id', save.data.id)
            resp = await FilesService.saveFile(datos, this.$store)
            console.log('RESPUESTA DE GUARDAR IMAGEN', resp)
            if (resp.status) {
              datos.delete('description')
              datos.delete('file')
              datos.delete('restriction_id')
            }
          }
          if (resp != null) {
            if (resp.status) {
              this.$swal({
                title: 'Registrado',
                text: 'Los datos han sido registrados con exito.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              // this.$router.push('/restricciones')
            } else {
              this.$swal({
                title: 'Error!',
                text: 'Ocurrió un error al registrar los archivos.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: 'Ocurrió un error al guardar los archivos.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
        this.$swal({
          title: 'Registrado',
          text: 'Los datos han sido registrados con exito.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.$router.push('/restricciones')
      } else {
        this.$swal({
          title: 'Error!',
          text: save.data.message ? save.data.message : 'Ocurrió un error al registrar los datos de los formularios.',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.showLoading = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
