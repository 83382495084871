var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('h5',[_vm._v("Lead Time")]),_c('validation-provider',{staticClass:"d-flex",attrs:{"name":"lead-time","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Lead Time (Dias)","label-for":"lead-time"}},[_c('b-form-input',{attrs:{"id":"lead-time","readonly":!_vm.items.leadtime_modified,"placeholder":"Lead Time","disabled":_vm.view ? true : false},on:{"input":function($event){return _vm.setLead(_vm.items.leadtime)}},model:{value:(_vm.items.leadtime),callback:function ($$v) {_vm.$set(_vm.items, "leadtime", $$v)},expression:"items.leadtime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('span',{staticClass:"w-50 d-flex justify-content-start px-2 align-items-center"},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1","disabled":_vm.view ? true : false},model:{value:(_vm.items.leadtime_modified),callback:function ($$v) {_vm.$set(_vm.items, "leadtime_modified", $$v)},expression:"items.leadtime_modified"}},[_vm._v(" Modificar ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"justify","rules":{
                  requeridoE: _vm.items.leadtime_modified ? true : false
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Justificación","label-for":"justify"}},[_c('b-form-textarea',{attrs:{"id":"justify","placeholder":"Justificación","rows":"10","disabled":!_vm.items.leadtime_modified,"no-resize":""},model:{value:(_vm.items.leadtime_comment),callback:function ($$v) {_vm.$set(_vm.items, "leadtime_comment", $$v)},expression:"items.leadtime_comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('h5',[_vm._v("Localización")]),_c('validation-provider',{attrs:{"name":"location1","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 1","label-for":"location1"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion1,"label":"code","input-id":"location1","reduce":function (locacion1) { return locacion1.id; },"placeholder":"Localización 1","disabled":_vm.view ? true : false},on:{"input":function($event){return _vm.changeLocal(_vm.items.location_1_id, 1)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_1_id),callback:function ($$v) {_vm.$set(_vm.items, "location_1_id", $$v)},expression:"items.location_1_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"location2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 2","label-for":"location2"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion2,"label":"code","input-id":"location2","reduce":function (locacion2) { return locacion2.id; },"placeholder":"Localización 2","disabled":_vm.view ? true : false},on:{"input":function($event){return _vm.changeLocal(_vm.items.location_2_id, 2)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_2_id),callback:function ($$v) {_vm.$set(_vm.items, "location_2_id", $$v)},expression:"items.location_2_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"location3","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 3","label-for":"location3"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion3,"label":"code","input-id":"location3","reduce":function (locacion3) { return locacion3.id; },"placeholder":"Localización 3","disabled":_vm.view ? true : false},on:{"input":function($event){return _vm.changeLocal(_vm.items.location_3_id, 3)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_3_id),callback:function ($$v) {_vm.$set(_vm.items, "location_3_id", $$v)},expression:"items.location_3_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"location4","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 4","label-for":"location4"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion4,"label":"code","input-id":"location4","reduce":function (locacion4) { return locacion4.id; },"placeholder":"Localización 4","disabled":_vm.view ? true : false},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_4_id),callback:function ($$v) {_vm.$set(_vm.items, "location_4_id", $$v)},expression:"items.location_4_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Actividad")])])],1),_c('b-row',{},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"id-activity","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ID Actividad","label-for":"id-activity"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.actividades,"label":"code","input-id":"id-activity","reduce":function (actividades) { return actividades.id; },"placeholder":"Actividad","disabled":_vm.view ? true : false},on:{"input":function($event){return _vm.selectActivity(_vm.items.activity_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.activity_id),callback:function ($$v) {_vm.$set(_vm.items, "activity_id", $$v)},expression:"items.activity_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"activity","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Actividad","label-for":"activity"}},[_c('b-form-input',{attrs:{"readonly":"","id":"activity","placeholder":"Actividad","disabled":_vm.view ? true : false},model:{value:(_vm.activity_code),callback:function ($$v) {_vm.activity_code=$$v},expression:"activity_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }