<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <h5>Definición de Fechas</h5>
            </b-row>
            <b-row class="mb-1">
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="date_iden" rules="requeridoE">
                  <b-form-group label="Fecha Identificada" label-for="date_iden">
                    <flat-pickr                      
                      id="date_iden"
                      v-model="items.date_identified"                      
                      class="form-control"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6"></b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="date_req" rules="requeridoE">
                  <b-form-group label="Fecha Requerida" label-for="date_req">
                    <flat-pickr
                    @input="diferenDate(items.date_required, 2), editDate()"                
                      id="date_req"
                      v-model="items.date_required"
                      :config="config"
                      class="form-control"
                      :disabled="view ? true : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="date_comp" rules="requeridoE">
                  <b-form-group label="Fecha Comprometida" label-for="date_comp">
                    <flat-pickr
                      @input="diferenDate(items.date_commited, 1), editDate()"
                      id="date_comp"
                      v-model="items.date_commited"
                      :config="config"
                      class="form-control"
                      :disabled="view ? true : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="date_lib" rules="">
                  <b-form-group label="Fecha Liberada" label-for="date_lib">
                    <flat-pickr
                      @input="editDate()"
                      id="date_lib"
                      v-model="items.date_released"
                      :disabled="view ? true : false"
                      :config="config"
                      class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6"> </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="status" rules="requeridoE">
                  <b-form-group label="Estado" label-for="status">
                    <!-- <b-form-input v-model="items.status" id="status" placeholder="Estado" /> -->
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="estados"
                      label="text"
                      input-id="status"
                      :reduce="(estados) => estados.value"
                      v-model="items.status"
                      placeholder="Estado"
                      :disabled="view ? true : false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="t-antici" rules="requeridoE|mayor">
                  <b-form-group label="T. Anticipación (Dias)" label-for="t-antici">
                    <b-form-input
                      readonly
                      v-model="items.time_anticipation"
                      id="t-antici"
                      placeholder="T. Anticipación"
                      :disabled="view ? true : false"
                    />
                    <small class="text-danger" v-if="Number(items.time_anticipation) < Number(items.leadtime)">T. Anticipación es menor a Lead Time</small>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>      
              <b-col md="6" class="d-flex flex-column">
                
                <b-form-group class="w-100" label="Lead Time (Dias)" label-for="lead-time">
                    <b-form-input
                      v-model="items.leadtime"
                      id="lead-time"
                      placeholder="Lead Time"
                      disabled
                    />
                  </b-form-group>
              </b-col>      
                 
              <!-- <b-col md="6" v-if="isChange && edit">
                <validation-provider #default="{ errors }" name="descript" rules="requeridoE">
                  <b-form-group label="Justificación" label-for="descript" class="w-100">
                    <b-form-textarea
                      id="descript"
                      placeholder="Descripción"
                      rows="2"
                      max-rows="2"
                      no-resize
                    ></b-form-textarea>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-if="isChange && edit">
                <validation-provider #default="{ errors }" name="descript" rules="requeridoE">
                  <b-form-group label="Prioridad" label-for="descript" class="w-100">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="prioritys"
                      label="name"
                      input-id="descript"
                      :reduce="(prioritys) => prioritys.name"
                      placeholder="Estado"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col> -->
            </b-row>
            {{ tiempoAnti }}
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import vSelect from 'vue-select'
import moment from 'moment'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  props: ['edit', 'view'],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr
  },
  //COMENTARIO
  data() {
    return {
      showLoading: false,
      tabIndex: 0,
      isChange: false,
      date1: new Date(),
      estados: [
        {
          text: 'Pendiente Por Oficializar',
          value: 'PendientePorOficializar'
        },
        {
          text: 'Rechazado',
          value: 'Rechazado'
        },
        {
          text: 'Oficializado',
          value: 'Oficializado'
        },
        {
          text: 'Liberado por Planner',
          value: 'LiberadoPorPlanner'
        },
        {
          text: 'Liberado Por Dueño',
          value: 'LiberadoPorDueño'
        },       
      ],
      prioritys: [
        {
          id: 1,
          name: 'Alta'
        },
        {
          id: 2,
          name: 'Media'
        },
        {
          id: 3,
          name: 'Baja'
        }
      ],
      config: {
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ],
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: [
              'Ene',
              'Feb',
              'Mar',
              'Abr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Sep',
              'Оct',
              'Nov',
              'Dic'
            ],
            longhand: [
              'Enero',
              'Febrero',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        }
      },   
      items: {
        date_identified: new Date(),
        date_required: new Date(),
        date_commited: null,
        time_anticipation: null,
        date_released: null,
        status: '',
        leadtime: null
      }
    }
  },
  computed: {
    tiempoAnti() {
      console.log('tiempoAnti')
      if (this.items.date_identified != null && this.items.date_required != null) {
        let date1T = moment(this.items.date_identified).format('yyyy-MM-DD')
        let date2T = moment(this.items.date_required).format('yyyy-MM-DD')
        let date1 = moment(date1T)
        let date2 = moment(date2T)
        if(this.items.status == 'PendientePorOficializar' || this.edit == false){
          this.items.time_anticipation = date2.diff(date1, 'days') < 0 ? 0 : date2.diff(date1, 'days')
        }
      }
    }
  },
  mounted() {
    console.log('this.items', this.items)
    if(!this.edit){
      this.items.status = 'Oficializado'
    }
    
  },

  methods: {
    editDate() {
      if (this.edit) {
        this.isChange = true
      }
    },
    
    diferenDate(fecha, val) {
      console.log('diferenDate',fecha)
      console.log('val',val)
      if (fecha != null) {
        if (val == 1) {
          let date1T = moment(fecha).format('yyyy-MM-DD')
          let date2T = moment(this.items.date_required).format('yyyy-MM-DD')
          let date1 = moment(date1T)
          let date2 = moment(date2T)
          if (date1.diff(date2, 'days') > 0) {
          
            this.$swal({
              icon: 'warning',
              title: 'Fecha inválida',
              text: 'La fecha comprometida no puede ser mayor a la fecha requerida',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
            //this.items.date_commited = new Date()
          }
        } else {
          let date1T = moment(this.items.date_commited).format('yyyy-MM-DD')
          let date2T = moment(fecha).format('yyyy-MM-DD')
          let date1 = moment(date1T)
          let date2 = moment(date2T)
        
          if (date2.diff(date1, 'days') < 0) {
            this.$swal({
              icon: 'warning',
              title: 'Fecha inválida',
              text: 'La fecha requerida no puede ser menor a la fecha comprometida',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
            //this.items.date_required = new Date()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}
.shortcut-buttons-flatpickr-button:hover{
  background-color: rgb(129, 119, 230);
}
</style>
