var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('h5',[_vm._v("Definición de Fechas")])]),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"date_iden","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Identificada","label-for":"date_iden"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_iden","disabled":""},model:{value:(_vm.items.date_identified),callback:function ($$v) {_vm.$set(_vm.items, "date_identified", $$v)},expression:"items.date_identified"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"date_req","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Requerida","label-for":"date_req"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_req","config":_vm.config,"disabled":_vm.view ? true : false},on:{"input":function($event){_vm.diferenDate(_vm.items.date_required, 2), _vm.editDate()}},model:{value:(_vm.items.date_required),callback:function ($$v) {_vm.$set(_vm.items, "date_required", $$v)},expression:"items.date_required"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"date_comp","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Comprometida","label-for":"date_comp"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_comp","config":_vm.config,"disabled":_vm.view ? true : false},on:{"input":function($event){_vm.diferenDate(_vm.items.date_commited, 1), _vm.editDate()}},model:{value:(_vm.items.date_commited),callback:function ($$v) {_vm.$set(_vm.items, "date_commited", $$v)},expression:"items.date_commited"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"date_lib","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Liberada","label-for":"date_lib"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_lib","disabled":_vm.view ? true : false,"config":_vm.config},on:{"input":function($event){return _vm.editDate()}},model:{value:(_vm.items.date_released),callback:function ($$v) {_vm.$set(_vm.items, "date_released", $$v)},expression:"items.date_released"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"status","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Estado","label-for":"status"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.estados,"label":"text","input-id":"status","reduce":function (estados) { return estados.value; },"placeholder":"Estado","disabled":_vm.view ? true : false},model:{value:(_vm.items.status),callback:function ($$v) {_vm.$set(_vm.items, "status", $$v)},expression:"items.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"t-antici","rules":"requeridoE|mayor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"T. Anticipación (Dias)","label-for":"t-antici"}},[_c('b-form-input',{attrs:{"readonly":"","id":"t-antici","placeholder":"T. Anticipación","disabled":_vm.view ? true : false},model:{value:(_vm.items.time_anticipation),callback:function ($$v) {_vm.$set(_vm.items, "time_anticipation", $$v)},expression:"items.time_anticipation"}}),(Number(_vm.items.time_anticipation) < Number(_vm.items.leadtime))?_c('small',{staticClass:"text-danger"},[_vm._v("T. Anticipación es menor a Lead Time")]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Lead Time (Dias)","label-for":"lead-time"}},[_c('b-form-input',{attrs:{"id":"lead-time","placeholder":"Lead Time","disabled":""},model:{value:(_vm.items.leadtime),callback:function ($$v) {_vm.$set(_vm.items, "leadtime", $$v)},expression:"items.leadtime"}})],1)],1)],1),_vm._v(" "+_vm._s(_vm.tiempoAnti)+" ")],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }